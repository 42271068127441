/* XLSX Parser */
body {
    /* font-family: Verdana; */
    /*padding: 20px;*/
}

.grid {
    width: 100%;
    height: 250px;
}

.grid-msg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.grid-msg-overlay .msg {
    opacity: 1;
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 50%;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #555;
    text-align: center;
    font-size: 24px;
    display: table;
}

.grid-msg-overlay .msg > .center {
    display: table-cell;
    vertical-align: middle;
}

.grid input[type="file"] {
    font-size: 14px;
    display: inline-block;
}

/* Custom changes*/
.container {
    width: 100%;
}
.navbar-toggle{
    background-color:white;
}
.white, .white a {
    color: #fff;
}
.red {
    background-color: #920003;
    padding-top: 25px;
}
.red-no-padding {
    background-color: #920003;
}
.caro {
    padding-top: 98px;
}
.as-sortable-dragging{
    padding-left: 30px;
    padding-top: 50px;
}
@media (min-width: 530px) {
    .map {
        background-image: url("../images/world.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        height:500px;
    }

}

@media (min-width: 0px) and (max-width: 530px) {
    .map {
        background-image: url("../images/world.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        height:315px;
    }
}
body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}
.content{
    margin-bottom: 20px;
}
.main-content {
    padding-top: 0px;
    padding-bottom: 10px;
    border-color: #FFF;
    /*width: 60%;*/
    margin: auto;
}

.footer {
    background-color: #5B9BD5;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 9pt;
}
.footer a {
    color:black;
}
.footer a:hover {
    color:black;
}
.footer ul {
    list-style: none;
}
/*hide bootstrap gradients*/
.carousel-control.left, .carousel-control.right {
    background-image: none
}
.banner-button2{
    border-color: gold;
    color: gold;
}
.banner-button{
    border-color: gold;
    color: gold;
}
/*banner buttons*/
@media (min-width: 355px) and (max-width: 525px) {
    .banner-button{
        margin-left:0px;
        margin-right:0px;

    }
}
@media (min-width: 0px) and (max-width: 354px) {
    .banner-button{
        margin-left:-9px;
        margin-right:-9px;

    }
}
@media (min-width: 526px) {
    .banner-button{
        margin-left:19px;
        margin-right:19px;

    }
}
.banner-button span{
    color: white;
}
.banner-button i{
    color: white;
    padding-top: 10px;
}
.banner-button a:link, a:visited, a:hover, a:focus, a:active {
    border-color: gold;
    color: white;
}



.main-logo{
    margin: 10px;
    height: 150px;
}

.header-text a:link, a:visited, a:hover, a:focus, a:active {
    text-decoration:none;
    color:#33509A;
}
.header-text {
    margin-top:25px;
    text-decoration:none;
    color:#33509A;
    font-size: 22pt;
    font-family: 'Times New Roman', serif;
}
.main_nav {
    height:190px;
    margin-bottom: 0;
}

@media (min-width: 0px) and (max-width: 430px) {
    .header-text {
        font-size: 16pt;
        margin-top:12px;
    }
    .main-logo{
        margin: 10px;
        height: 90px;
    }
    .main_nav {
        margin-bottom: 0;
        height:130px;
    }
}
.red-gold-button {
    font-size: 13pt;
}
/* allows hover */
/* Small devices (tablets, 768px and up) */
@media (min-width: 0px) and (max-width: 768px) {
    .secondary_nav .navbar-nav > li {
        width: 110%;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color:white;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1043px) {
    .secondary_nav .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .nav > li > a {
        /*padding-top: 0px;*/
        margin-top: 0;
        padding-right: 10px;
        padding-left: 10px;
    }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 1043px) and (max-width: 1200px) {
    .secondary_nav .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .nav > li > a {
        padding-right: 13px;
        padding-left: 13px;
    }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1300px) {
    .secondary_nav .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .nav > li > a {
        padding-right: 30px;
        padding-left: 30px;
    }
}
/* Extra Large devices (large desktops, 1500px and up) */
@media (min-width: 1507px) {
    .nav > li > a {
        padding-right: 45px;
        padding-left: 45px;
    }

    .secondary_nav .navbar-nav li > .dropdown-menu {
        color: black;
        margin-left: 40px;
    }
}


/*custom navbar changes*/

.secondary_nav {
    background-color: #33509A;
    padding-top: 0;
    margin-bottom: 0;
}

.secondary_nav .navbar-nav {
    width: 100%;
    text-align: center;
}
.secondary_nav .navbar-nav > li {
    float: none;
    display: inline-block;
}

.secondary_nav .navbar-nav li > .header-menu {
    color: white;
    font-size:13pt;
}
.secondary_nav .navbar-nav li > .header-menu:hover {
    color: white;

}
.secondary_nav .navbar-nav li > .dropdown-menu {
    color: black;
    font-size:12pt;
}
.secondary_nav .navbar-nav > .active > a {
    background-color: #337AB7;
    color: white;
}
/*.navbar-default .navbar-nav > .active > a:hover,*/
/*.navbar-default .navbar-nav > .active > a:focus*/


/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.development {
    position: absolute;
    width: 149px;
    height: 149px;
    top: 0;
    left: 0;
    border: 0;
    z-index: 1000;
    background-image: url("../images/development_ribbon.png");
    background-position: center center;
    background-repeat: no-repeat;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.error {
    color: white;
    background-color: red;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

.hand {
    cursor: pointer;
}

#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */

.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* end of Custom alerts for notification */

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

/* start Password strength bar style */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}
/* end Password strength bar style */

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* entity tables helpers */

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

/* Align text in td verifically (top aligned by Bootstrap) */
.jh-table > tbody > tr > td {
    vertical-align: middle;
}
/* end of entity tables helpers */

/* ui bootstrap tweaks */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus{
    outline: none;
}
/* end of ui bootstrap tweaks */

/* jhipster-needle-scss-add-main JHipster will add new css style */

/* jhipster-needle-css-add-main JHipster will add new css style */

.btn-sq-lg {
    width: 150px !important;
    height: 150px !important;
}

.btn-sq {
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

.btn-sq-sm {
    width: 50px !important;
    height: 50px !important;
    font-size: 10px;
}

.btn-sq-xs {
    width: 25px !important;
    height: 25px !important;
    padding:2px;
}

